import get from "lodash/get";

/**
 * Selectors are helper functions that generalize common questions about state.
 *
 * It introduces an extra layer of indirection, but it pays for this
 * by providing a single source of truth for how to interpret the state.
 *
 * This makes it easier to reduce duplication and bad assumptions,
 * and to change the shape of state in the future.
 *
 * A note on functions that return boolean values:
 * If the function is written as `isAccountInSomeState`, it should only be used
 * to determine if that specific condition true. A false value should not be used to infer
 * some other state. For example, if `isMicrodeposit` is false for some relationship,
 * you should not assume that it is a Plaid relationship. There could be some third type
 * of relationship in the future, or the relationship could just be null.
 */

// account stuff

export const isAlpacaAccountCreated = (state) =>
  !!(state.account && state.account.account);

export const getAlpacaAccount = (state) => get(state, "account.account");

export const getAlpacaAccountId = (state) => get(state, "account.account.id");

export const getPaperAccountId = (state) => get(state, "account.paper.current");

export const getApiToken = (state) =>
  get(state, "auth.userState.signInUserSession.idToken.jwtToken");

// A helper to return access token, account id and paper account id.
export const getAccountIdAndJWT = (state) => {
  const account = getAlpacaAccount(state) || {};
  const accountId = account.id || "";
  const paperAccountId = getPaperAccountId(state);
  const token = getApiToken(state);

  return { accountId, paperAccountId, token };
};

// banking stuff

export const isMicrodeposit = (relationship) =>
  relationship &&
  relationship.plaid_verification_status &&
  relationship.plaid_verification_status.includes("manual");

export const isPendingMicrodeposit = (relationship) =>
  isMicrodeposit(relationship) &&
  relationship.plaid_verification_status.includes("pending");

/**
 * This will return the account ID for the given product.
 * Currently there is only one account per product, but this
 * will change in the future.
 *
 * @param {Object} state
 * @param {String} product
 */
export const getAccountIdForProduct = (state, product) => {
  if (product && state) {
    // Figure out the account id to use
    const { account } = state;
    let accountId = "";
    if (product === "paper") {
      accountId = (account.paper && account.paper.current) || accountId;
    }
    if (product === "live") {
      accountId = (account.account && account.account.id) || accountId;
    }

    return accountId;
  }
};

/**
 * Given a live account object, return if the account is in a state
 * of beinga be to be used.
 *
 * @param {Object} account
 */
export const isLiveAccountActive = (account) => {
  const accountStatus = (account && account.status) || false;

  // A full list of status values here:
  // https://github.com/alpacahq/gobroker/blob/develop/models/enum/enum.go#L17
  //
  // An 'ACTIVE' status will have an account_number so no need to check that.
  // SUBMITTED          - action is required on client side, some sort of rejection
  // RESUBMITTED        - request has been re-submitted to Apex after account update
  // APPROVAL_PENDING   - account awaiting approval
  // REAPPROVAL_PENDING - indeterminate state once apex approves a re-submission
  // ACTION_REQUIRED    - account did not pass automatic cip check or failed watchlist check, waiting for admin action
  // SUBMISSION_FAILED  - something happened on our server and didn't complete
  // REJECTED           - APEX flat out rejected the account (the user must re-apply somehow - new email/login)
  // :::  the following admin actions can change status :::
  // ACCOUNT_UPDATED    - if the account was previously in an ACTIVE status
  // EDITED             - temporary status when an admin has edited an account
  // ACCOUNT_CLOSED     - the account is closed (rare)
  // DISABLED           - ETC account is disabled by admin
  return accountStatus === "ACTIVE";
};

/**
 * Given an account object, this will return true if the
 * account is PAPER_ONLY or ONBOARDING
 *
 * @param {Object} account
 */
export const isPaperOrOnboarding = (account) => {
  const liveAccount = account?.account ?? account?.liveAccount ?? {};
  const status = liveAccount?.status ?? "";
  return (
    status === "ONBOARDING" || status === "PAPER_ONLY" || status === "SIGNED_UP"
  );
};

// Check on name for disabled
export const isLiveAccountClosedOrDisabled = (account) => {
  const liveAccount = account.account || {};
  const { status = "" } = liveAccount;
  return status === "ACCOUNT_CLOSED" || status === "DISABLED";
};
