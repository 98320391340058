import _ from "lodash";
import AmplitudeProvider from "src/AmplitudeProvider";
import useGetAssets from "../../../api/hooks/useGetAssets";

import { useGetAccount, useGetOptionsApprovalStatus } from "src/v2/api/hooks";

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  Button,
  Icon,
  SidebarState,
  UIContext,
  Combobox,
  classes,
} from "@alpacahq/ui";
import { Box, BoxProps } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import { isPaperOrOnboarding } from "src/v2/utils";
import { Asset } from "../../../api/rest";
import { getCryptoAssets } from "../../../api/rest/data";
import { AssetLike } from "../../../pages/dashboard/trade/Trade";
import { Path } from "../../../path";
import { isOptionsOnboardingAvailable } from "src/v2/pages/dashboard/optionsOptIn/util";
import {
  OptionsTopBarButton,
  OptionsMobileButton,
} from "./OptionsTopBarButton";

const BANKING_AND_ONBOARDING_PATHS = [
  Path.ROUTE_NEW_ACCOUNT,
  Path.ROUTE_ENTITY_ONBOARDING_BUSINESS_INFO,
  Path.ROUTE_ENTITY_ONBOARDING_ACCOUNT_OPENER,
  Path.ROUTE_ENTITY_ONBOARDING_AUTH_INDIVIDUALS_UBOS,
  Path.ROUTE_ENTITY_ONBOARDING_DOCUMENTS,
];

const SEARCH_RESULTS_LIMIT = 10;

// a map of assets sorted by first letter and then word groups for fast searching
type AssetMap = {
  // letter group
  [key: string]: {
    // word group
    [key: string]: Partial<Asset>;
  };
};

export const TopBar = (props: BoxProps & { product: string }) => {
  const history = useHistory();

  const [assets, setAssets] = useState<AssetLike[]>([]);

  const { sidebarState, setSidebarState } = useContext(UIContext);
  const { account, isAuthenticated } = useGetAccount();

  const { data: crypto } = useQuery("crypto", getCryptoAssets);

  const { assets: equities } = useGetAssets("equities", {
    enabled: !!isAuthenticated,
  });

  const { data: optionsApprovalStatus } = useGetOptionsApprovalStatus(
    ["options-opt-in", account?.id],
    account?.id,
    {
      enabled: !!account?.id,
    }
  );

  // normalize asset symbol and name for searching
  const normalize = useCallback(
    (value: string) =>
      String(value)
        .toUpperCase()
        .trim()
        .replace(/[^a-z\s-]/gi, ""),
    []
  );

  useEffect(() => {
    // filter out assets that are already in the list
    const filtered = (crypto || [])
      .concat(equities)
      .filter((asset) => !assets.some((a) => a.symbol === asset.symbol));

    // add new assets to the list
    if (filtered.length) {
      setAssets([...assets, ...filtered]);
    }
  }, [equities, crypto]);

  // memoize assets into index map for fast searching
  const assetMap = useMemo<AssetMap>(
    () =>
      assets
        .filter((asset) => asset.symbol)
        .map(({ symbol, name }) => ({ symbol, name }))
        .reduce((prev, next) => {
          const symbol = normalize(next.symbol);
          const name = normalize(next.name);
          const keyForSymbol = symbol[0];
          const keyForName = name[0];

          // create symbol character group
          if (!prev[keyForSymbol]) {
            prev[keyForSymbol] = {};
          }

          // create name character group
          if (!prev[keyForName]) {
            prev[keyForName] = {};
          }

          // add asset to symbol and name groups
          prev[keyForSymbol][symbol] = next;
          prev[keyForName][name] = next;

          return prev;
        }, {} as AssetMap),
    [assets]
  );

  const onSearch = useCallback(
    (query: string) => {
      // normalize query string for searching
      const normalized = normalize(query);

      // find and set match based on first letter
      const match = assetMap[normalized.charAt(0)];

      // if no match, set empty array
      if (!match) {
        return [];
      }

      // sort by symbol in descending alphabetic order
      const matched = Object.keys(match)
        .filter((key) => key.startsWith(normalized))
        .map((key) => match[key])
        .sort((a, b) => {
          const matchA = a.symbol?.startsWith(normalized);
          const matchB = b.symbol?.startsWith(normalized);

          // if both match, sort
          if (matchA && matchB) {
            return (a.symbol?.length || 0) - (b.symbol?.length || 0);
          }

          if (matchA) {
            // negative number means a comes first
            return -1;
          } else if (matchB) {
            // positive number means b comes first
            return 1;
          } else {
            // if neither match, return 0
            return 0;
          }
        });

      return (
        _.uniqBy(matched, (asset) => asset.symbol)
          // we only care about the first X results
          .slice(0, SEARCH_RESULTS_LIMIT)
          // filter out assets without symbol
          .filter(({ symbol }) => !!symbol)
          // map to suggestion object
          .map(
            // cast as string because we already filtered out assets without symbol or name
            ({ symbol, name }) => ({
              label: symbol as string,
              description: name || "no asset description found",
              href: Path.ROUTE_TRADE.replace(":symbol", symbol as string),
            }),
            []
          )
      );
    },
    [assetMap]
  );

  // handle the deposit or open account button click
  const onDepositOrOpenAccountClick = useCallback(() => {
    // track the event
    AmplitudeProvider.dispatch(
      props.product === "paper"
        ? "navbar_open_live_account_button_clicked"
        : "navbar_deposit_funds_button_clicked"
    );

    // if the account is paper or onboarding, go to the new account page
    if (props.product === "paper") {
      history.push(Path.ROUTE_NEW_ACCOUNT);
    } else {
      // if the account is not paper or onboarding, go to the deposit page
      history.push(Path.ROUTE_FUNDING);
    }
  }, [history, props.product]);

  // handle state transition logic
  const handleSidebarState = (currentSidebarState: SidebarState) => {
    switch (currentSidebarState) {
      case SidebarState.HIDDEN:
        return SidebarState.MOBILE;
      case SidebarState.MOBILE:
        return SidebarState.HIDDEN;
      case SidebarState.EXPANDED:
        return SidebarState.COLLAPSED;
      case SidebarState.COLLAPSED:
        return SidebarState.EXPANDED;
      default:
        localStorage.removeItem("sidebarState");
        return null;
    }
  };

  const isInBankingOrOnboardingPath = BANKING_AND_ONBOARDING_PATHS.some(
    (path) => window.location.pathname.includes(path)
  );

  const isDepositOrOpenAccountBtnDisplayed =
    // hide button while onboarding
    !isInBankingOrOnboardingPath &&
    (isPaperOrOnboarding(account) || props.product === "live") &&
    // hide button if live account cannot add funds
    !(
      props.product === "live" &&
      account?.status !== "ACTIVE" &&
      account?.status !== "ACCOUNT_UPDATED"
    );

  const isOptionsUpgradable = account?.options_approved_level !== 3;

  // Only display the options opt-in button for people can apply or upgrade
  const isOptionsButtonDisplayed = useMemo(
    () =>
      isOptionsOnboardingAvailable(account, optionsApprovalStatus, {
        allowLegalEntities: true,
      }) && isOptionsUpgradable,
    [account, optionsApprovalStatus]
  );

  const isOptionsUpgrading = (account?.options_approved_level ?? 0) > 0;

  const onOptionsClick = () =>
    history.push(
      account?.is_legal_entity
        ? Path.ROUTE_OPTIONS_OPT_IN_ENTITY_ACCOUNT
        : Path.ROUTE_OPTIONS_OPT_IN_FINANCIAL_PROFILE
    );

  return (
    <Box
      id="topbar"
      w="100%"
      zIndex={2}
      display="flex"
      flexDir="column"
      {...props}
    >
      <div
        className={classes(
          "flex flex-row gap-3 items-center p-6 xs:pl-0 md:pr-6 z-5 mt-[1px]"
        )}
      >
        {sidebarState === SidebarState.HIDDEN && (
          <Button
            className="px-3"
            variant="secondary"
            onClick={() => {
              const newState = handleSidebarState(sidebarState);
              if (newState !== null) {
                setSidebarState(newState);
                localStorage.setItem("sidebarState", newState);
              }
            }}
          >
            <Icon className="h-4 w-4" name="Bars3" />
          </Button>
        )}
        <div className="w-full max-w-[300px]">
          <Combobox
            placeholder="Search"
            options={onSearch}
            getOptionKey={(o) => o.label}
            getOptionLabel={(o) => o.label}
            getOptionSecondary={(o) => o.description}
            value={null}
            onChange={(o) => {
              if (o) history.push(o.href);
            }}
            optionSpacing="justify-between"
            icon="MagnifyingGlass"
          />
        </div>
        {sidebarState !== SidebarState.HIDDEN && (
          <>
            <div className="flex-1" />
            {isOptionsButtonDisplayed && (
              <OptionsTopBarButton
                isUpgrade={isOptionsUpgrading}
                onClick={onOptionsClick}
              />
            )}

            {isDepositOrOpenAccountBtnDisplayed && (
              <Button variant="secondary" onClick={onDepositOrOpenAccountClick}>
                {props.product === "paper" ? (
                  "Open Live Account"
                ) : (
                  <div className="flex flex-row gap-2">
                    <Icon name="Plus" className="h-4 w-4" />
                    <span className="whitespace-nowrap">Add Funds</span>
                  </div>
                )}
              </Button>
            )}
          </>
        )}
      </div>
      {isOptionsButtonDisplayed && (
        <OptionsMobileButton
          isUpgrade={isOptionsUpgrading}
          onClick={onOptionsClick}
        />
      )}
    </Box>
  );
};

export default TopBar;
