import moment from "moment";
import instance from "..";

export interface Quote {
  symbol: string;
  bid_timestamp: string;
  bid: number;
  bid_size: number;
  bid_exchange: string;
  ask_timestamp: string;
  ask: number;
  ask_size: number;
  ask_exchange: string;
  last_timestamp: string;
  last: number;
  last_size: number;
  last_exchange: string;
  last_day_closing: string;
}

export const getQuotes = async (
  symbols: string[],
  source?: string
): Promise<Quote[]> => {
  const res = await instance.get(
    `/quotes?symbols=${symbols.join(",")}&source=${source}`
  );

  return res.data;
};

export interface Clock {
  timestamp: string;
  is_open: boolean;
  next_open: string;
  next_close: string;
}

export const getClock = async (): Promise<Clock> => {
  const res = await instance.get("/clock");
  return res.data;
};

// get orderbooks
export const getOrderbooks = async (symbols: string[]): Promise<any> => {
  const res = await instance.get(
    `/latest/orderbooks?symbols=${symbols.join(",")}`,
    {
      baseURL: window.env.CRYPTO_DATA_API_URL,
    }
  );
  return res.data;
};

/**
export const getTrades = async (symbols: string[]): Promise<any> => {
  const meow: any = [];
  const limit = 500;

  const next: any = async (token?: string) => {
    const { data } = await instance.get(
      `/trades?symbols=${symbols.join(",")}&limit=${limit}${
        !token ? `&start=${moment().subtract(1, "minutes").toISOString()}` : ""
      }${token ? `&page_token=${token}` : ""}`,
      {
        baseURL: window.env.CRYPTO_DATA_B2_API_URL,
      }
    );

    if (data.trades[symbols[0]]) {
      meow.push(...data.trades[symbols[0]]);
    }

    const next_page_token = data.next_page_token;

    return await next(next_page_token);
  };

  if (meow.length == 0) {
    await next();
  }

  console.log(meow);

  return meow;
};
 **/

export interface CryptoSnapshot {
  dailyBar: {
    c: number;
    h: number;
    l: number;
    n: number;
    o: number;
    t: string;
    v: number;
    vw: number;
  };
  latestQuote: {
    ap: number;
    as: number;
    bp: number;
    bs: number;
    t: string;
  };
  latestTrade: {
    i: number;
    p: number;
    s: number;
    t: string;
    tks: string;
  };
  minuteBar: {
    c: number;
    h: number;
    l: number;
    n: number;
    o: number;
    t: string;
    v: number;
    vw: number;
  };
  prevDailyBar: {
    c: number;
    h: number;
    l: number;
    n: number;
    o: number;
    t: string;
    v: number;
    vw: number;
  };
}

export interface CryptoSnapshots {
  snapshots: {
    [key: string]: CryptoSnapshot;
  };
}

export interface GetCryptoSnapshotParams {
  symbols: string[];
}

// get crypto snapshot
export const getCryptoSnapshots = async (
  params: GetCryptoSnapshotParams
): Promise<CryptoSnapshots> => {
  const res = await instance.get(
    `/snapshots?symbols=${params.symbols.join(",")}`,
    {
      baseURL: window.env.CRYPTO_DATA_API_URL,
    }
  );
  return res.data;
};

export interface CryptoAsset {
  id: string;
  class: string;
  exchange: string;
  symbol: string;
  name: string;
  status: string;
  tradable: boolean;
  marginable: boolean;
  maintenance_margin_requirement: number;
  shortable: boolean;
  easy_to_borrow: boolean;
  fractionable: boolean;
  min_order_size: string;
  min_trade_increment: string;
  price_increment: string;
}

// we have to use this for now; not exposed on staging
export const getCryptoAssets = (): Promise<CryptoAsset[]> =>
  instance
    .get("/assets/public/beta", {
      baseURL: "https://api.alpaca.markets/v2",
    })
    .then(({ data }) => data);

export interface OptionContract {
  id: string;
  symbol: string;
  name: string;
  status: string;
  tradable: boolean;
  expiration_date: string;
  root_symbol: string;
  underlying_symbol: string;
  underlying_asset_id: string;
  type: string;
  style: string;
  strike_price: string;
  size: string;
  open_interest: string;
  open_interest_date: string;
  close_price: string;
  close_price_date: string;
  multiplier: string;
}

export type OptionContractsResponse = {
  option_contracts: OptionContract[];
  page: number;
  limit: number;
};

type OptionsContractsParams = {
  underlying_symbol?: string;
  expiration_date_gte?: string;
  expiration_date?: string;
  type?: "call" | "put";
  product: string;
};

export const getOptionsContracts = async ({
  underlying_symbol,
  expiration_date_gte = moment().format("YYYY-MM-DD"),
  expiration_date,
  type,
  product,
}: OptionsContractsParams): Promise<OptionContractsResponse> => {
  const baseURL =
    product === "paper" ? window.env.PAPER_API_ENDPOINT : window.env.API_URL;

  const params = new URLSearchParams({
    ...(!expiration_date && { expiration_date_gte }), // Don't need to set expiration_date_gte if expiration_date is present
    ...(expiration_date && { expiration_date }),
    ...(underlying_symbol && { underlying_symbol }),
    ...(type && { type }),
    limit: "10000",
  });

  const { data } = await instance.get(
    `/options/contracts?${params.toString()}`,
    { baseURL }
  );

  return data;
};

export const getOptionsContract = async ({
  symbol,
  product,
}: {
  symbol: string;
  product?: string;
}): Promise<OptionContract> => {
  const baseURL =
    product === "paper" ? window.env.PAPER_API_ENDPOINT : window.env.API_URL;

  const { data } = await instance
    .get(`/options/contracts/${symbol}`, { baseURL })
    .catch(() => ({} as any));

  return data;
};

export interface GetOptionsSnapshotParams {
  symbols: string[];
  limit?: string;
}

export interface GetOptionSnapshotsResponse {
  next_page_token: string | null;
  snapshots: {
    [symbol: string]: {
      latestQuote: {
        ap: number;
        as: number;
        ax: string;
        bp: number;
        bs: number;
        bx: string;
        c: string;
        t: string;
      };
      latestTrade: {
        c: string;
        p: number;
        s: number;
        t: string;
        x: string;
      };
      dailyBar?: {
        t: string;
        o: number;
        h: number;
        l: number;
        c: number;
        v: number;
        n: number;
        vw: number;
      };
      minuteBar?: {
        t: string;
        o: number;
        h: number;
        l: number;
        c: number;
        v: number;
        n: number;
        vw: number;
      };
      prevDailyBar?: {
        t: string;
        o: number;
        h: number;
        l: number;
        c: number;
        v: number;
        n: number;
        vw: number;
      };
      greeks?: {
        delta: number;
        gamma: number;
        rho: number;
        theta: number;
        vega: number;
      };
      impliedVolatility?: number;
    };
  };
}

export const getOptionContractSnapshot = async ({
  symbols,
  limit = "100",
}: GetOptionsSnapshotParams) => {
  const params = new URLSearchParams({
    symbols: symbols.join(","),
    limit,
  });

  const res = await instance.get<GetOptionSnapshotsResponse>(`/snapshots`, {
    baseURL: window.env.OPTION_CHAIN_DATA_API_URL,
    params,
  });

  return res.data;
};

export interface GetOptionsBarsParams {
  symbols: string[];
  timeframe?: "1D";
}

export interface GetOptionsBarsResponse {
  bars: {}[];
  currency?: string;
}

export const getOptionsBars = async ({
  symbols,
  timeframe = "1D",
}: GetOptionsBarsParams) => {
  const params = new URLSearchParams({
    symbols: symbols.join(","),
    timeframe,
  });

  const res = await instance.get<GetOptionsBarsResponse>("/bars", {
    baseURL: "https://data.staging-v2.tradetalk.us/v1beta1/options",
    params,
  });

  return res.data;
};

export interface GetOptionChainSnapshotsParams {
  underlying_symbol: string;
  limit?: string;
  expiration_date?: string;
  expiration_date_gte?: string;
}

export const getOptionChainSnapshots = async ({
  underlying_symbol,
  limit = "1000",
  expiration_date_gte = moment().format("YYYY-MM-DD"),
  expiration_date,
}: GetOptionChainSnapshotsParams) => {
  const params = new URLSearchParams({
    ...(!expiration_date && { expiration_date_gte }), // Don't need to set expiration_date_gte if expiration_date is present
    ...(expiration_date && { expiration_date }),
    limit,
  });

  const res = await instance.get<GetOptionSnapshotsResponse>(
    `/snapshots/${underlying_symbol}`,
    {
      baseURL: window.env.OPTION_CHAIN_DATA_API_URL,
      params,
    }
  );

  return res.data;
};
