import React, { useEffect } from "react";

import {
  Button,
  Checkbox,
  CheckboxState,
  Form,
  useModal,
  useUIForm,
} from "@alpacahq/ui";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useGetMargin, useMutationRequest } from "src/v2/api/hooks";
import {
  createPaperAccount,
  createPaperAccountParams,
  PaperAccount,
} from "src/v2/api/rest/account";
import { useToast } from "@chakra-ui/react";
import { useAccountContext } from "src/v2/providers/AccountProvider";
import { getPrettyCash } from "src/v2/utils/formatting";

export interface CreatePaperAccountModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const MIN_FUNDS = 1;
const MAX_FUNDS = 1000000;

const schema = z.object({
  cash: z.coerce.number().gte(MIN_FUNDS).lte(MAX_FUNDS).optional(),
  name: z.string(),
  match: z.boolean(),
});

type CreatePaperAccountModalForm = z.infer<typeof schema>;

export const CreatePaperAccountModal: React.FC<
  CreatePaperAccountModalProps
> = ({ isOpen, onClose }) => {
  const toast = useToast();

  const { liveAccounts, refetchPaperAccounts } = useAccountContext();

  const accountId = liveAccounts?.[0]?.id;
  const { margin } = useGetMargin(["margin", accountId], accountId, false, {
    enabled: !!accountId,
  });

  const createPaperAccountMutation = useMutationRequest<
    PaperAccount,
    createPaperAccountParams
  >((formValues) => createPaperAccount(formValues), {
    onSuccess: () => {
      toast({
        title: "Success",
        description: "You have successfully created a new account",
        status: "success",
      });

      refetchPaperAccounts();
      onClose();
    },
  });

  const form = useUIForm<CreatePaperAccountModalForm>({
    resolver: zodResolver(schema),
    defaultValues: {
      match: false,
    },
  });

  const matchLiveEquity = form.watch("match");

  const handleSubmit = ({ match, cash, name }: CreatePaperAccountModalForm) => {
    const values = {
      cash: match ? parseFloat(margin?.equity ?? "0") : cash ? cash : 100000,
      name,
    };
    createPaperAccountMutation.mutate(values);
  };

  const {
    component: Modal,
    isOpen: isModalOpen,
    toggle,
  } = useModal({
    title: "Open New Paper Account",
    content: (
      <div className="space-y-2">
        <div>
          Create a new paper account to simulate trades and test strategies. You
          can have up to 3 paper accounts.
        </div>
        <Form formMethods={form} onSubmit={handleSubmit}>
          <Form.TextInput label="Nickname" name="name" />

          <Form.TextInput
            type="number"
            label="Set Funds"
            name="cash"
            isDisabled={matchLiveEquity}
            placeholder={`${getPrettyCash(MIN_FUNDS)} - ${getPrettyCash(
              MAX_FUNDS
            )}`}
          />

          <div
            className="flex gap-1 cursor-pointer"
            onClick={() => {
              form.setValue("match", !matchLiveEquity);
            }}
          >
            <Checkbox
              state={
                matchLiveEquity
                  ? CheckboxState.Checked
                  : CheckboxState.Unchecked
              }
            />
            Match current live account equity balance?
          </div>
        </Form>
      </div>
    ),
    controls: (
      <div className="flex space-x-2">
        <Button variant="alternate" onClick={() => toggle()}>
          Close
        </Button>
        <Button onClick={form.handleSubmit(handleSubmit)}>Submit</Button>
      </div>
    ),
    onClose: () => {
      if (isModalOpen) {
        onClose();
      }
    },
  });

  useEffect(() => {
    if (isOpen && !isModalOpen) {
      toggle();
    }
  }, [isOpen, isModalOpen, toggle]);

  return <>{Modal}</>;
};
export default CreatePaperAccountModal;
