import React, { useEffect } from "react";
import { useGetAccount, useGetAccountDetails } from "./v2/api/hooks";
import { Account, getIntercomUserHash } from "./v2/api/rest/account";
import { initializeIntercom } from "./utils/intercom";

type IntercomProps = {
  cognito?: {
    status: string;
    payload: Account[] | null;
  };
};

export const Intercom = ({ cognito }: IntercomProps) => {
  const { account } = useGetAccount("intercom", {
    enabled: cognito?.status === "loaded" && cognito?.payload !== null,
  });
  const { details } = useGetAccountDetails("details", account?.id, {
    enabled: !!account?.id,
  });

  useEffect(() => {
    if (details && account) {
      getIntercomUserHash()
        .then((userHash) => initializeIntercom({ account, details }, userHash))
        .catch((err) => console.error("Intercom Error", err));
    }
  }, [account, details]);

  return null;
};
