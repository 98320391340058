import React from "react";
import { Button, Icon } from "@alpacahq/ui";

export type OptionsButtonProps = {
  isUpgrade: boolean;
  onClick: () => void;
};

// Desktop Options Opt In button - shortens text on small screens, on top bar
export const OptionsTopBarButton = ({
  isUpgrade,
  onClick,
}: OptionsButtonProps) => (
  <Button
    onClick={onClick}
    variant="ghost"
    className="hidden min-[479px]:flex border-2 border-yellow-300"
  >
    <div className="min-[700px]:hidden flex flex-row gap-1">
      <Icon name="Plus" className=" h-4 w-4" />
      Options
    </div>
    <div className="hidden min-[700px]:contents">
      {isUpgrade ? "Upgrade Your Options Level" : "Get Started with Options"}
    </div>
  </Button>
);

// Mobile Options Opt In button - full width centered text, below top bar
export const OptionsMobileButton = ({
  isUpgrade,
  onClick,
}: OptionsButtonProps) => (
  <Button
    onClick={onClick}
    variant="ghost"
    className="min-[479px]:hidden mx-8 mb-6 border-2 border-yellow-300 justify-center"
  >
    {isUpgrade ? "Upgrade Your Options Level" : "Get Started with Options"}
  </Button>
);
