import { useQuery } from "react-query";
import { getCountries } from "../rest/onboarding";

export type Country = {
  name: string;
  "alpha-3": string;
  "country-code": string;
};

export const useGetCountries = () => {
  const { data: countries = [] } = useQuery("countries", getCountries, {
    // It's so unlikely that this will change, we can cache it forever
    staleTime: Infinity,
  });

  return {
    countries,
    countriesRaw: countries,
    alpha3ToName: (alpha3: string) =>
      countries.find(({ ["alpha-3"]: short }) => short === alpha3)?.name,
    nameToAlpha3: (name: string) =>
      countries.find(({ name: n }) => n === name)?.["alpha-3"],
  };
};

export type UseGetCountriesReturn = ReturnType<typeof useGetCountries>;

export default useGetCountries;
