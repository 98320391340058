import { startCase } from "lodash";
import { BusinessInfo } from "./pages/businessInfo/schema";
import { AccountOpener } from "./pages/accountOpener/schema";
import {
  AuthorizedIndividual,
  UltimateBeneficialOwner,
} from "./pages/authIndividualsUBOs/schema";
import { Nullable } from "./util";
import { Documents } from "./pages/documents/schema";
import { CountryInfo } from "src/v2/types";
import { Agreements } from "./pages/agreements/schema";

// Add "other" back when it is supported by back end
export const ENTITY_TYPES = [
  "limited_liability_corporation",
  "s_corporation",
  "c_corporation",
  "revocable_trust",
  "irrevocable_trust",
  "limited_partnership",
  "general_partnership",
  "foreign_financial_institution",
  "banking_institution",
  "broker_or_dealer",
  "fund_or_hedge_fund",
  "unincorporated",
  // "other",
] as const;

export const ENTITY_TYPES_OPTIONS = ENTITY_TYPES.map((value) => ({
  value,
  label: startCase(value),
}));

export const FUNDING_SOURCES = [
  "business_revenue",
  "asset_appreciation",
  "sales_of_assets",
  "investors",
] as const;

export const FUNDING_SOURCES_OPTIONS = FUNDING_SOURCES.map((value) => ({
  value,
  label: startCase(value),
}));

export const BUSINESS_TYPES = [
  "administration_or_public_relations",
  "agriculture",
  "architecture",
  "arts_or_film_or_music",
  "construction",
  "design",
  "education",
  "finance",
  "food_production",
  "government_or_public_services",
  "healthcare",
  "legal_services",
  "media_or_marketing",
  "oil_or_energy_or_mining",
  "pharmaceuticals_or_biochemicals",
  "real_estate",
  "retail_restaurants",
  "technology",
  "sports_or_sports_medicine_or_fitness",
  "tourism",
  "transport",
  "other",
] as const;

export const BUSINESS_TYPES_OPTIONS = BUSINESS_TYPES.map((value) => ({
  value,
  label: startCase(value),
}));

export const INTENDED_ACCOUNT_USES = [
  "long_or_short_term_savings",
  "income_generation",
  "market_speculation",
  "preserve_capital",
] as const;

export const INTENDED_ACCOUNT_USES_OPTIONS = INTENDED_ACCOUNT_USES.map(
  (value) => ({ value, label: startCase(value) })
);

export const DATE_18_YEARS_AGO = new Date(
  new Date().setFullYear(new Date().getFullYear() - 18)
);

export const RANGES = {
  "1": { min: 0, max: 250000 },
  "2": { min: 250001, max: 750000 },
  "3": { min: 750001, max: 10000000 },
  "4": { min: 10000001, max: 25000000 },
  "5": { min: 25000001, max: 49999999 },
  "6": { min: 50000000, max: 100000000 },
};

export const RANGE_OPTIONS = Object.entries(RANGES).map(([key, value]) => {
  return {
    value: key,
    label: `$${value.min.toLocaleString()}${
      value.max === RANGES[6].max
        ? " or above"
        : ` - ${value.max.toLocaleString()}`
    }`,
  };
});

export const REQUIRED_ERR_MSG = "Required";

export const USA = "United States of America";

export const USA_STATES_MAP = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FM: "Federated States Of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MH: "Marshall Islands",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PW: "Palau",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export const AFFILIATION_BOOLEAN_KEYS = [
  "is_control_person",
  "is_affiliated_exchange_or_finra",
  "is_politically_exposed",
];

export const FILE_MAX_SIZE = 20 << (10 * 2); // 20MB
export const FILE_SIZE_LABEL = "Max File size: 20 MB";
export const FILE_SIZE_ERR_MSG = "Max file size is 20MB.";
export const FILE_ACCEPTED_TYPES = [
  "application/pdf",
  "application/json",
  "image/png",
  "image/jpeg",
  "image/webp",
];
export const FILE_TYPE_ERR_MSG = `${FILE_ACCEPTED_TYPES.map((type) =>
  type.substring(type.indexOf("/") + 1)
).join(", ")} files are accepted.`;

export const DEFAULT_BUSINESS_INFO_VALUES: Nullable<BusinessInfo> = {
  is_legal_entity: true,
  legal_name: null,
  entity_type: null,
  entity_type_other_description: null,
  is_exempt_from_tax_under_501a: null,
  is_participating_fatca: null,
  type_of_business: null,
  type_of_business_other_free_text: null,
  country_of_incorporation: null,
  state_of_incorporation: null,
  date_of_incorporation: null,
  business_registration_number: null,
  country_of_tax_residence: null,
  tax_id: null,
  tax_id_type: null,
  email: null,
  phone_number: null,
  street_address: null,
  unit: null,
  city: null,
  state: null,
  postal_code: null,
  country: null,
  intended_use_of_account: null,
  liquid_net_worth_min: null,
  liquid_net_worth_max: null,
  total_net_worth_min: null,
  total_net_worth_max: null,
  annual_income_min: null,
  annual_income_max: null,
  funding_source: null,
};

export const DEFAULT_ACCOUNT_OPENER_VALUES: Nullable<AccountOpener> = {
  is_legal_account_opener: true,
  title_at_company: null,
  country_of_citizenship: null,
  phone_number: null,
  email: null,
  postal_code: null,
  street_address: null,
  unit: null,
  city: null,
  state: null,
  country_of_tax_residence: null,
  tax_id: null,
  tax_id_type: null,
  date_of_birth: null,
  given_name: null,
  middle_name: null,
  family_name: null,
  is_affiliated_exchange_or_finra: null,
  affiliated_firm: null,
  is_control_person: null,
  controlling_firms: null,
  is_politically_exposed: null,
  immediate_family_exposed: null,
};

export const DEFAULT_AUTHORIZED_INDIVIDUAL_VALUES: Nullable<AuthorizedIndividual> =
  {
    is_authorized_individual: true,
    country_of_citizenship: null,
    phone_number: null,
    email: null,
    postal_code: null,
    street_address: null,
    unit: null,
    city: null,
    state: null,
    country_of_tax_residence: null,
    tax_id: null,
    tax_id_type: null,
    date_of_birth: null,
    given_name: null,
    middle_name: null,
    family_name: null,
    is_affiliated_exchange_or_finra: null,
    affiliated_firm: null,
    is_control_person: null,
    controlling_firms: null,
    is_politically_exposed: null,
    immediate_family_exposed: null,
    title_at_company: null,
  };

export const DEFAULT_UBO_VALUES: Nullable<UltimateBeneficialOwner> = {
  is_ubo: true,
  country_of_citizenship: null,
  phone_number: null,
  email: null,
  postal_code: null,
  street_address: null,
  unit: null,
  city: null,
  state: null,
  country_of_tax_residence: null,
  tax_id: null,
  tax_id_type: null,
  date_of_birth: null,
  given_name: null,
  middle_name: null,
  family_name: null,
  is_affiliated_exchange_or_finra: null,
  affiliated_firm: null,
  is_control_person: null,
  controlling_firms: null,
  is_politically_exposed: null,
  immediate_family_exposed: null,
  percentage_ownership: null,
  title_at_company: null,
};

export const DEFAULT_DOCUMENTS_VALUES: Documents = {
  entity_operating_confirmed: false,
  authorized_individual_id_confirmed: false,
  ubo_id_confirmed: false,
  entity_operating_uploaded: false,
  entity_registration_confirmed: false,
  entity_registration_uploaded: false,
  w9_uploaded: false,
  w_8ben_uploaded: false,
  w_8bene_uploaded: false,
  entity_formation_confirmed: false,
  entity_formation_uploaded: false,
};

export const DEFAULT_AGREEMENTS_VALUES: Agreements = {
  certifications_and_resolutions_accepted: false,
  ubo_acknowledged: false,
  customer_agreement_acknowledged: false,
  digital_signature_acknowledged: false,
  id_verification_acknowledged: false,
};

export const TAX_FORM_URLS = {
  W9: "https://www.irs.gov/pub/irs-pdf/fw9.pdf",
  W8_BENE: "https://www.irs.gov/pub/irs-pdf/fw8bene.pdf",
};

export const CUSTOMER_AGREEMENT_URL =
  "https://files.alpaca.markets/disclosures/library/AcctAppMarginAndCustAgmt.pdf";

/** Legal entity cannot use these tax ID types */
export const NOT_ALLOWED_LEGAL_ENTITY_TAX_ID_TYPES = [
  "USA_SSN",
  "USA_ITIN",
  "USA_TIN",
];

/** Account openers, authorized individuals, and UBOs cannot use these tax ID types */
export const NOT_ALLOWED_OWNER_TAX_ID_TYPES = ["USA_EIN"];

export const NOT_SPECIFIC_TAX_ID_TYPE: NonNullable<
  CountryInfo["tax_id_types"]
>[number] = {
  id_type: "NOT_SPECIFIED",
  label: "Other",
  regexp: "^.*$",
};

export type EntityOnboardingHistoryState = {
  isInEntityOnboarding?: boolean;
  isEntityOnboardingCompleted?: boolean;
};
