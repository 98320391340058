import instance from "..";
import { Portfolio } from "./account";

export interface PortfolioHistoryResponse {
  arrays: [number[], number[], number[], number[]];
  attributes: { name: string; type: string }[];
  base_value: number;
  base_value_asof: string; // YYYY-MM-DD
  timeframe: string;
}

export interface GetPortfolioArgs {
  accountID: string;
  product: string;
  intraday_reporting?: "market_hours" | "extended_hours" | "continuous";
  start?: string;
  end?: string;
  pnl_reset?: "per_day" | "no_reset";
  period?: "1D" | "1W" | "1M" | "1A" | "all";
}

export const getPortfolioHistory = async ({
  accountID,
  product,
  start,
  end,
  pnl_reset,
  period = "1D",
  intraday_reporting = "extended_hours",
}: GetPortfolioArgs) => {
  const isLiveAccount = product === "live";

  const endpoint = `/${
    isLiveAccount ? "accounts" : "paper_accounts"
  }/${accountID}/portfolio/history`;

  const res = await instance.get<PortfolioHistoryResponse>(endpoint, {
    params: {
      ...(start && { start }),
      ...(end && { end }),
      ...(pnl_reset && { pnl_reset }),
      ...(period && { period }),
      ...(intraday_reporting && { intraday_reporting }),
    },
  });

  return res.data;
};
