import { UseMutationResult, useMutation } from "react-query";
import { DocUploadData } from "../rest/account";
import { acceptW8 } from "../rest/onboarding";

interface PostW8BenRequest {
  accountId: string;
}

interface PostW8BenMutationProps {
  onSuccess: () => void;
  onError: (e: unknown) => void;
  onSettled?: () => void;
  onMutate?: () => void;
}

export const usePostW8Ben = ({
  onSuccess,
  onError,
  onMutate,
  onSettled,
}: PostW8BenMutationProps): UseMutationResult<
  object,
  object,
  PostW8BenRequest,
  unknown
> => {
  return useMutation({
    mutationFn: (request) => acceptW8(request.accountId),
    onMutate: (): void => {
      onMutate?.();
    },
    onSuccess: (): void => {
      onSuccess();
    },
    onError: (e): void => {
      onError(e);
    },
    onSettled: (): void => {
      onSettled?.();
    },
  });
};
