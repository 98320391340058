import React from "react";

import { Icon } from "@alpacahq/ui";
import { Account } from "../../../api/rest/account";
import { Path } from "../../../path";
import { isPaperOrOnboarding } from "../../../utils";

export type PaperBannerProps = {
  account?: Account;
};

export const PaperBanner = ({ account }: PaperBannerProps) => (
  <div className="rounded-lg bg-blue-100 px-4 h-10 flex items-center mt-6 mx-6 xs:ml-0">
    <Icon name="InformationCircle" className="h-5 w-5 text-blue-600 mr-2" />
    <p className="text-base font-medium text-blue-600">
      <span>You are on Paper Trading, no real money is being used.&nbsp;</span>
      {account && !isPaperOrOnboarding(account) ? (
        <a
          className="font-semibold underline hover:underline"
          href={Path.format(Path.ROUTE_DASHBOARD, { product: "live" })}
        >
          Switch to Live Trading?
        </a>
      ) : null}
    </p>
  </div>
);
