import UI from "@alpacahq/ui";
import React, { useEffect } from "react";

import { ReactNode } from "react";

import { Box, ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { useHistory } from "react-router-dom";
import { customTheme } from "../../v2/theme";
import { useDarkMode } from "../api/hooks/useDarkMode";

import "react-datepicker/dist/react-datepicker.css";
import "react-phone-input-2/lib/style.css";
// @deprecated
import "@alpacahq/sassy/dist/theme.css";
import "@alpacahq/ui/theme";
import "../theme.scss";
import AmplitudeProvider from "src/AmplitudeProvider";

export const client = new QueryClient({
  defaultOptions: { queries: { staleTime: 10000 } },
});

const App = (props: { children: ReactNode }) => {
  const { useDarkModeValue } = useDarkMode();
  const history = useHistory();

  useEffect(() => {
    AmplitudeProvider.dispatchPageVisit();
  }, [history.location.pathname]);

  return (
    <UI.Provider history={useHistory()}>
      <ChakraProvider theme={{ ...customTheme(useDarkModeValue) }}>
        <QueryClientProvider client={client}>
          <Box id="anchor" />
          {props.children}
        </QueryClientProvider>
      </ChakraProvider>
    </UI.Provider>
  );
};

export default App;
