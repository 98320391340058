import { useRequest } from ".";
import {
  GetPortfolioArgs,
  getPortfolioHistory,
  PortfolioHistoryResponse,
} from "../rest/portfolio";
import { QueryOptions } from "./useRequest";

export interface UseGetPortfolioHistoryArgs extends GetPortfolioArgs {
  options?: QueryOptions<PortfolioHistoryResponse>;
}

export const useGetPortfolioHistory = ({
  options,
  ...rest
}: UseGetPortfolioHistoryArgs) => {
  const { data, ...restResponse } = useRequest(
    ["portfolio", ...Object.values(rest)],
    () => getPortfolioHistory({ ...rest }),
    {
      ...options,
      // todo: revisit this override of default behavior (duplicate error handling)
      onError: () => null,
    }
  );

  return { portfolio: data, ...restResponse };
};

export default useGetPortfolioHistory;
