import useRequest, { QueryOptions } from "./useRequest";

import { UseQueryResult } from "react-query";
import { DocumentRequestResponse, getAccountDocuments } from "../rest/account";

export const useGetAccountDocumentUploads = (
  accountId?: string,
  options?: QueryOptions<DocumentRequestResponse[]>
): UseQueryResult<DocumentRequestResponse[]> => {
  return useRequest(
    ["account_document_uploads", accountId],
    () => getAccountDocuments(accountId || ""),
    {
      ...options,
    }
  );
};
