import { createAction, createReducer } from "redux-act";
import "fast-text-encoding";
import { updateIntercom } from "src/utils/intercom";

const REDUCER = "profitloss";
const NS = `@@${REDUCER}/`;

const _setProfitLoss = createAction(`${NS}SET_PROFIT_LOSS`);

const initialState = {};
export default createReducer(
  {
    [_setProfitLoss]: (state, { accountId, product, ownerId, profitloss }) => {
      if (!profitloss) {
        return state;
      }

      const newState = { ...state };
      const previousRetrieval = newState[accountId]?.previousRetrieval;

      if (!previousRetrieval) {
        // For now, only one live trading account
        if (product === "live" && ownerId) {
          updateIntercom(ownerId, {
            profitLossDay: profitloss.day_plpc,
            profitLossTotal: profitloss.total_plpc,
          });
        }
      }

      newState[accountId] = profitloss;
      newState[accountId].previousRetrieval = true;
      return newState;
    },
  },
  initialState
);
