export const Path = {
  // routes
  ROUTE_LOGIN: "/account/login",
  ROUTE_DASHBOARD: "/:product/dashboard/overview",
  ROUTE_POSITIONS: "/account/positions",
  ROUTE_ORDERS: "/account/orders",
  ROUTE_TRADE: "/trade/:symbol",
  ROUTE_ACCOUNT_ACTIVITIES: "/account/activities",
  ROUTE_DOCUMENTS: "/account/documents",
  ROUTE_BALANCES: "/:product/dashboard/balances",
  ROUTE_CONFIGURATION: "/account/configuration",
  ROUTE_IP_ALLOWLIST: "/account/ip-allowlist",
  ROUTE_CRYPTO_TRANSFERS: "/:product/dashboard/crypto-transfers",
  ROUTE_ALPACA_CONNECT: "/connect",
  ROUTE_ALPACA_CONNECT_SUBMIT: "/connect/submit",
  ROUTE_ALPACA_CONNECT_APP: "/connect/:client_id",
  ROUTE_ALPACA_PROFILE: "/user/profile#:tab",
  ROUTE_NEW_ACCOUNT: "/brokerage/new-account",
  ROUTE_USER_SUBSCRIPTION: "/user/subscription",
  ROUTE_PLANS_AND_FEATURES: "/account/plans-and-features",
  ROUTE_USER_SUBSCRIPTION_NEW: "/brokerage/new-account/data-subscription?upd=1",
  ROUTE_OPTIONS_OPT_IN_FINANCIAL_PROFILE:
    "/brokerage/options/opt-in/financial-profile",
  ROUTE_OPTIONS_OPT_IN_INVESTMENT_EXPERIENCE:
    "/brokerage/options/opt-in/investment-experience",
  ROUTE_OPTIONS_OPT_IN_SPREADS: "/brokerage/options/opt-in/spreads",
  ROUTE_OPTIONS_OPT_IN_AGREEMENT: "/brokerage/options/opt-in/options-agreement",
  ROUTE_OPTIONS_OPT_IN_STATUS: "/brokerage/options/opt-in/status",
  ROUTE_OPTIONS_OPT_IN_ENTITY_ACCOUNT:
    "/brokerage/options/opt-in/entity-account",
  ROUTE_ENTITY_ONBOARDING_BUSINESS_INFO: "/brokerage/new-entity-account/business-info",
  ROUTE_ENTITY_ONBOARDING_ACCOUNT_OPENER:
    "/brokerage/new-entity-account/account-opener",
  ROUTE_ENTITY_ONBOARDING_AUTH_INDIVIDUALS_UBOS:
    "/brokerage/new-entity-account/authorized-individuals-ubos",
  ROUTE_ENTITY_ONBOARDING_DOCUMENTS: "/brokerage/new-entity-account/documents",
  ROUTE_ENTITY_ONBOARDING_AGREEMENTS: "/brokerage/new-entity-account/agreements",
  ROUTE_ENTITY_ONBOARDING_COMPLETED: "/brokerage/new-entity-account/completed",
  ROUTE_ALPACA_TRADING_PLAN: "/user/trading-plan",

  ROUTE_FUNDING: "/brokerage/funding",
  ROUTE_FUNDING_HISTORY: "/brokerage/funding/history",
  ROUTE_FUNDING_LINK: "/brokerage/funding/link",
  ROUTE_FUNDING_LINK_ACH: "/brokerage/funding/link/ach",
  ROUTE_FUNDING_LINK_WIRE: "/brokerage/funding/link/wire",
  ROUTE_FUNDING_LINK_CURRENCYCLOUD: "/brokerage/funding/link/currencycloud",
  ROUTE_FUNDING_DEPOSIT: "/brokerage/funding/deposit",
  ROUTE_FUNDING_DEPOSIT_ACH: "/brokerage/funding/deposit/ach",
  ROUTE_FUNDING_DEPOSIT_WIRE: "/brokerage/funding/deposit/wire",
  ROUTE_FUNDING_DEPOSIT_CURRENCYCLOUD:
    "/brokerage/funding/deposit/currencycloud",
  ROUTE_FUNDING_WITHDRAW: "/brokerage/funding/withdraw",
  ROUTE_FUNDING_WITHDRAW_ACH: "/brokerage/funding/withdraw/ach",
  ROUTE_FUNDING_WITHDRAW_WIRE: "/brokerage/funding/withdraw/wire",
  ROUTE_FUNDING_WITHDRAW_CURRENCYCLOUD:
    "/brokerage/funding/withdraw/currencycloud",

  ROUTE_HIGH_YIELD_ONBOARDING: "/user/high-yield-cash-enrollment",

  // urls
  URL_BUG_REPORT: "https://forms.gle/32B6T3bbWzL6agbC7",
  URL_DOCS: "https://docs.alpaca.markets/docs",
  URL_DOCS_ACCOUNT_ACTIVITIES:
    "https://docs.alpaca.markets/docs/account-activities",
  URL_SLACK: "https://alpaca.markets/slack",
  URL_FORUM: "https://forum.alpaca.markets",
  URL_SUPPORT: "https://alpaca.markets/support",
  URL_DISCLOSURES: "https://alpaca.markets/disclosures",
  // helpers
  format: (path: string, params: { [key: string]: string }) =>
    Object.keys(params).reduce(
      (acc, key) =>
        acc.replace(`:${key}`, params[key].replace("live", "brokerage")),
      path
    ),
};
