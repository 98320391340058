import { UseMutationResult, useMutation } from "react-query";
import { DocUploadData, uploadAccountDocument } from "../rest/account";

interface PostAccountDocumentRequest {
  docId: string;
  payload: DocUploadData;
}

interface PostAccountDocumentMutationProps {
  onSuccess: (response: DocUploadData) => void;
  onError: (e: unknown) => void;
  onSettled?: () => void;
  onMutate?: () => void;
}

export const usePostAccountDocument = ({
  onSuccess,
  onError,
  onMutate,
  onSettled,
}: PostAccountDocumentMutationProps): UseMutationResult<
  DocUploadData,
  object,
  PostAccountDocumentRequest,
  unknown
> => {
  return useMutation({
    mutationFn: (request) =>
      uploadAccountDocument(request.docId, request.payload),
    onMutate: (): void => {
      onMutate?.();
    },
    onSuccess: (response): void => {
      onSuccess(response);
    },
    onError: (e): void => {
      onError(e);
    },
    onSettled: (): void => {
      onSettled?.();
    },
  });
};
