import { getAccountIdAndJWT } from "selectors";

// REST IS DEPRECATED.
//
// GET, POST, DELETE etc. styles are recommended. See rest.js for more info.
// See the relationship methods for examples.
import { default as REST, DELETE, GET, PATCH, POST } from "./rest";
import { getCookie } from "lib/cookies";
import { accountCreated, accountCreationFailed } from "reducers/auth/signup";

export const owner = {
  get: REST("GET", "/owner"), // TODO use GET() instead of REST()
  patch: (payload, token) => REST("PATCH", "/owner")(payload, token), // TODO use PATCH() instead of REST()
  create: POST("/owner", { public: true }),
  requestEmail: POST("/owner/email"),
  verifyEmail: POST("/owner/email-confirm"),
  joinCryptoWaitlist: POST("/owner/waitlist/crypto"),
};

export const account = {
  create: (urlParams, payload, token) => {
    // signup_source default value is 'alpaca' in cognito. so if it's 'alpaca'
    // we don't want to send it to gobroker
    if (payload.source === "alpaca") payload.source = null;
    const refBy = getCookie("alpaca_ref_by");
    if (refBy) {
      payload["referral_code"] = refBy;
    }
    return async (dispatch) =>
      dispatch(POST("/accounts")(urlParams, payload, token)).then(
        (response) => dispatch(accountCreated(response)),
        (error) => dispatch(accountCreationFailed(error))
      );
  },
  patch: REST("PATCH", "/accounts"), // TODO use PATCH() instead of REST()
  list: GET("/accounts"),
  resetPassword: REST("POST", "/accounts/actions/reset_password"), // TODO use POST() instead of REST()
  requestResetPassword: REST(
    // TODO use GET() instead of REST()
    "POST",
    "/accounts/actions/request_reset_password"
  ),
  verifyEmail: REST("POST", "/accounts/actions/verify_email"), // TODO use POST() instead of REST()
  requestVerifyEmail: REST("POST", "/accounts/actions/request_verify_email"), // TODO use POST() instead of REST()
};

export const tradeAccount = {
  get: GET("/accounts/:accountId/trade_account/margin"),
  getETC: GET("/accounts/:accountId/trade_account/margin"),
  getETCLast: GET("/accounts/:accountId/trade_account/margin/last"),
};

export const paperTradeAccount = {
  get: GET("/paper_accounts/:accountId/trade_account/margin"),
  getETC: GET("/paper_accounts/:accountId/trade_account/margin"),
  getETCLast: GET("/paper_accounts/:accountId/trade_account/margin/last"),
};

export const paperAccounts = {
  list: (id, token) => REST("GET", `/accounts/${id}/paper_accounts`)({}, token), // TODO use GET() instead of REST()
  get: (id, payload, token) =>
    REST(
      // TODO use GET() instead of REST()
      "GET",
      `/accounts/${id}/paper_accounts/${(payload && payload.paperId) || ""}`
    )({}, token),
  create: (id, payload, token) =>
    REST("POST", `/accounts/${id}/paper_accounts`)(
      // TODO use POST() instead of REST()
      { cash: (payload && payload.cash) || 100000 },
      token
    ),
  delete: (id, payload, token) =>
    REST(
      // TODO use DELETE() instead of REST()
      "DELETE",
      `/accounts/${id}/paper_accounts/${(payload && payload.paperId) || ""}`
    )({}, token),
  reset: POST("/accounts/:accountId/paper_accounts/:paperAccountId"),
};

export const ownerDetails = {
  get: (id, token) => REST("GET", `/accounts/${id}/details`)({}, token), // TODO use GET() instead of REST()
  patch: (id, payload, token) =>
    REST("PATCH", `/accounts/${id}/details`)(payload, token), // TODO use PATCH() instead of REST()
  patchESign: (id, payload, token) =>
    REST("PATCH", `/accounts/${id}/details/esign`)(payload, token),
  // for staging/local only
  resetOnboarding: (id, token) =>
    REST("PATCH", `/accounts/${id}/details/reset`)({}, token),
};

export const auth = {
  create: REST("POST", "/auth"), // TODO use POST() instead of REST()
};

export const recovery = {
  create: POST("/accounts/:cognitoId/account-recovery"),
  delete: DELETE("/accounts/:cognitoId/account-recovery"),
};

export const accessKeys = {
  list: (id, token) => REST("GET", `/access_keys`)({}, token), // TODO use GET() instead of REST()
  create: (id, accessToken) => REST("POST", "/access_keys")({}, accessToken), // TODO use POST() instead of REST()
  delete: (id, keyId, token) =>
    REST("DELETE", `/access_keys/${keyId}`)({}, token), // TODO use DELETE() instead of REST()
};

export const paperAccessKeys = {
  list: (paperAccountId, token) =>
    REST("GET", `/paper_accounts/${paperAccountId}/access_keys`)({}, token), // TODO use GET() instead of REST()
  create: (paperAccountId, accessToken) =>
    REST("POST", `/paper_accounts/${paperAccountId}/access_keys`)(
      {},
      accessToken
    ), // TODO use POST() instead of REST()
  delete: (paperAccountId, keyId, token) =>
    REST("DELETE", `/paper_accounts/${paperAccountId}/access_keys/${keyId}`)(
      {},
      token
    ), // TODO use DELETE() instead of REST()
};

export const addresses = {
  list: REST("GET", "/addresses"), // TODO use GET() instead of REST()
  get: (id, token) => REST("GET", `/addresses/${id}/details`)({}, token), // TODO use GET() instead of REST()
};

export const positions = {
  list: (id, token) => REST("GET", `/accounts/${id}/positions`)({}, token), // TODO use GET() instead of REST()
  symbol: (id, symbol, token) =>
    REST("GET", `/accounts/${id}/positions/${symbol}`)({}, token), // TODO use GET() instead of REST()
  liquidate: POST("/accounts/:accountId/positions/liquidate"),
  liquidatePosition: DELETE("/accounts/:accountId/positions/:symbol?qty=:qty"),
};

export const paperPositions = {
  list: (id, token) =>
    REST("GET", `/paper_accounts/${id}/positions`)({}, token), // TODO use GET() instead of REST()
  symbol: (id, symbol, token) =>
    REST("GET", `/paper_accounts/${id}/positions/${symbol}`)({}, token), // TODO use GET() instead of REST()
  liquidate: POST("/paper_accounts/:accountId/positions/liquidate"),
  liquidatePosition: DELETE(
    "/paper_accounts/:accountId/positions/:symbol?qty=:qty"
  ),
};

export const orders = {
  list: GET("/accounts/:accountId/orders"),
  listBySymbol: GET(
    "/accounts/:accountId/orders?status=:status&symbols=:symbol"
  ),
  create: POST("/accounts/:accountId/orders"),
  cancelAll: (id, token, params) =>
    REST("DELETE", `/accounts/${id}/orders`)(params, token),
  getOrder: GET("/accounts/:accountId/orders/:orderId"),
  cancelOrder: DELETE("/accounts/:accountId/orders/:orderId"),
  replaceOrder: PATCH("/accounts/:accountId/orders/:orderId"),
};

// TODO: Ultimately this should be refactoed to allow one api call, the backend should change to accept an "accountId" and "product"
export const paperOrders = {
  list: GET("/paper_accounts/:accountId/orders"),
  listBySymbol: GET(
    "/paper_accounts/:accountId/orders?status=:status&symbols=:symbol"
  ),
  create: POST(`/paper_accounts/:accountId/orders`),
  cancelAll: (id, token, params) =>
    REST("DELETE", `/paper_accounts/${id}/orders`)(params, token),
  getOrder: GET("/paper_accounts/:accountId/orders/:orderId"),
  cancelOrder: DELETE("/paper_accounts/:accountId/orders/:orderId"),
  replaceOrder: PATCH("/paper_accounts/:accountId/orders/:orderId"),
};

export const wireBanks = {
  list: GET("/accounts/:accountId/banks"),
  create: POST("/accounts/:accountId/banks"),
  delete: DELETE("/accounts/:accountId/banks/:bankId"),
};

export const rapyd = {
  wallet: {
    create: POST("/rapyd/:accountId/wallet"),
    get: GET("/rapyd/:accountId/wallet"),
  },
  transfer: {
    create: POST("/rapyd/:accountId/payment"),
    get: GET("/rapyd/:accountId/transfers"),
  },
  countries: {
    get: GET("/rapyd/supported-countries"),
  },
  paymentMethods: {
    get: GET("/rapyd/payment-methods"),
    getForCountry: GET("/rapyd/payment-methods/:country"),
  },
  exchange: {
    calculate: GET("/rapyd/exchange-rate?buy=:buy&sell=:sell"),
  },
};

export const currencyCloud = {
  create: POST("/currency_cloud/:accountId/funding_account"),
  get: GET("/currency_cloud/:accountId/funding_account"),
  status: GET("/currency_cloud/:accountId/status"),
  transfer: {
    get: GET("/currency_cloud/:accountId/transfers"),
    create: POST("/currency_cloud/:accountId/transfers"),
  },
  beneficiary: {
    get: GET("/currency_cloud/:accountId/beneficiary_required_details"),
    create: POST("/currency_cloud/:accountId/beneficiary"),
  },
};

export const rewards = {
  get: GET("/accounts/:accountId/claim-reward/:rewardName"),
  view: PATCH("/accounts/:accountId/reward-viewed/:rewardName"),
};

export const relationships = {
  list: GET("/accounts/:accountId/relationships"),
  create: POST("/accounts/:accountId/relationships"),
  delete: DELETE("/accounts/:accountId/relationships/:relationshipId"),
  verify: POST("/accounts/:accountId/relationships/:relationshipId/verify"),
  token: GET("/accounts/:accountId/relationships/:relationshipId/token"),
};

export const institutions = {
  get: GET("/institutions/:institutionId"),
};

export const plaidLinkToken = {
  get: GET("/accounts/:accountId/plaid/link/token"),
};

export const plaidAuth = {
  get: GET("/accounts/:accountId/plaid/auth_status"),
};

export const plaidStripe = {
  get: GET("/plaid/stripe/link/token"),
  post: POST("/plaid/stripe"),
};

export const transfers = {
  list: GET("/accounts/:accountId/transfers"),
  create: POST("/accounts/:accountId/transfers"),
  delete: DELETE("/accounts/:accountId/transfers/:transferId"),
};

export const activities = {
  list: GET("/accounts/:accountId/activities"),
};

export const paperActivities = {
  list: GET("/paper_accounts/:accountId/activities"),
};

export const documents = {
  list: (id, token, params) =>
    REST("GET", `/accounts/${id}/documents`)(params, token), // TODO use GET() instead of REST()
  url: GET("/accounts/:accountId/documents/:documentId/url"),
};

// New Alpaca account document system
export const accountDocuments = {
  listRequests: (id, token) =>
    REST("GET", `/documents/accounts/${id}/requests`)({}, token),
  listUploads: (id, token) =>
    REST("GET", `/documents/accounts/${id}`)({}, token),
  upload: (id, token, payload) =>
    REST(
      "POST",
      `/documents/${payload.docId}/upload?sub_type=${payload.subType}`
    )(payload.formData, token, { "Content-Type": "multipart/form-data" }),
  uploadByDocType: (id, token, payload) =>
    REST(
      "POST",
      `/documents/accounts/${id}/upload/${payload.docType}/${payload.docSubType}`
    )(payload.formData, token, { "Content-Type": "multipart/form-data" }),
};

export const profitloss = {
  get: GET("/accounts/:accountId/profitloss"),
};

export const paperProfitloss = {
  get: GET("/paper_accounts/:accountId/profitloss"),
};

export const portfolio = {
  get: GET("/accounts/:accountId/portfolio/history"),
};

export const paperPortfolio = {
  get: GET("/paper_accounts/:accountId/portfolio/history"),
};

export const dataAgreement = {
  get: (accountId, agreement, token) =>
    REST("GET", `/accounts/${accountId}/agreements/${agreement}/preview`)(
      // TODO use GET() instead of REST()
      {},
      token
    ),
  post: (accountId, agreement, token, agreementText) =>
    REST("POST", `/accounts/${accountId}/agreements/${agreement}/accept`)(
      // TODO use POST() instead of REST()
      agreementText ? { agreement_text: agreementText } : {},
      token
    ),
};

export const taxForm = {
  post: (accountId, formName, token) =>
    REST("POST", `/accounts/${accountId}/taxfiles/${formName}/accept`)(
      {},
      token
    ),
};

export const dataAgreementV2 = {
  get: GET("/accounts/:accountId/agreements/:agreement/preview"),
};

export const trustedContact = {
  get: (accountId, token) =>
    REST("GET", `/accounts/${accountId}/trusted_contact`)({}, token), // TODO use GET() instead of REST()
  post: (accountId, token, data) =>
    REST("POST", `/accounts/${accountId}/trusted_contact`)(data, token), // TODO use POST() instead of REST()
};

export const affiliates = {
  post: (accountId, token, payload) =>
    REST("POST", `/accounts/${accountId}/affiliates`)(payload, token), // TODO use POST() instead of REST()
  list: (accountId, token) =>
    REST("GET", `/accounts/${accountId}/affiliates`)({}, token), // TODO use GET() instead of REST()
  patch: (accountId, affiliateId, token, payload) =>
    REST("PATCH", `/accounts/${accountId}/affiliates/${affiliateId}`)(
      // TODO use PATCH() instead of REST()
      payload,
      token
    ),
  delete: (accountId, token, affiliateId) =>
    REST("DELETE", `/accounts/${accountId}/affiliates/${affiliateId}`)(
      // TODO use DELETE() instead of REST()
      {},
      token
    ),
};

export const onfidoApplicant = {
  post: POST(`/accounts/:accountId/onfido/applicant`),
  patch: PATCH(`/accounts/:accountId/onfido/applicant`),
};

export const onfidoSdkToken = {
  get: (accountId, token) =>
    REST("GET", `/accounts/${accountId}/onfido/sdk_token`)({}, token),
  patch: PATCH(`/accounts/:accountId/onfido/sdk`),
};

export const onfidoResubmission = {
  resubmit: POST("/accounts/:accountId/onfido/resubmit"),
};

export const configs = {
  list: GET(`/accounts/:accountId/configurations`),
  patch: PATCH(`/accounts/:accountId/configurations`),
};

export const paperConfigs = {
  list: GET(`/paper_accounts/:paperAccountId/configurations`),
  patch: PATCH(`/paper_accounts/:paperAccountId/configurations`),
};

export const billing = {
  plans: GET("/billing/plans", { public: true }),
  coupon: GET("/billing/coupon/:code"),
  overview: GET("/billing/overview"),
  stripe: {
    create: POST("/billing/stripe"),
    update: PATCH("/billing/stripe"),
    cancel: DELETE("/billing/stripe"),
  },
  alpaca: {
    create: POST("/billing/alpaca"),
    update: PATCH("/billing/alpaca"),
    cancel: DELETE("/billing/alpaca"),
  },
};

export const assets = {
  get: GET("/assets/:symbol"),
  search: GET("/assets/search"),
  list: GET("/assets?asset_class=:class"),
};

export const quotes = {
  get: GET("/quotes?symbols=:symbols"),
};

export const contests = {
  get: GET("/contests/:contestId", { public: true }),
  list: GET("/contests", { public: true }),
  enrollment: {
    get: GET(`/paper_accounts/:paperAccountId/contests/:contestId/enrollment`),
    post: POST(
      `/paper_accounts/:paperAccountId/contests/:contestId/enrollment`
    ),
    patch: PATCH(
      `/paper_accounts/:paperAccountId/contests/:contestId/enrollment`
    ),
    delete: DELETE(
      `/paper_accounts/:paperAccountId/contests/:contestId/enrollment`
    ),
  },
  leaderboard: {
    getAllTime: GET("/contests/leaderboard", { public: true }),
    get: GET("/contests/leaderboard/:contestId", { public: true }),
    getParticipant: GET("/contests/leaderboard/:contestId/:participantId", {
      public: true,
    }),
  },
};

export const countries = {
  getRestrictedCountries: GET("/restricted-countries"),
  getCountryInfos: GET("/country-infos"),
};

export const agreement = {
  get: GET("/accounts/:accountId/taxfiles/:agreement/preview"),
};

export const crypto = {
  list: GET("/crypto/fee-structure"),
  createFee: POST("/accounts/:accountId/crypto/fee"),
  getMonthlyVolume: GET("/accounts/:accountId/crypto/monthly-volume"),
};

// don't use this default export, it makes it impossible for tools to figure out what anything is.
export default {
  getAccountIdAndJWT, // re-exported from selectors for convenience
  owner,
  account,
  accountDocuments,
  ownerDetails,
  auth,
  accessKeys,
  paperAccessKeys,
  addresses,
  positions,
  paperPositions,
  orders,
  paperOrders,
  wireBanks,
  relationships,
  activities,
  paperActivities,
  institutions,
  transfers,
  documents,
  profitloss,
  paperProfitloss,
  tradeAccount,
  paperTradeAccount,
  paperAccounts,
  portfolio,
  paperPortfolio,
  dataAgreement,
  taxForm,
  trustedContact,
  affiliates,
  onfidoApplicant,
  onfidoSdkToken,
  onfidoResubmission,
  configs,
  paperConfigs,
  assets,
  contests,
  quotes,
  rapyd,
  countries,
  rewards,
  currencyCloud,
  recovery,
  agreement,
  dataAgreementV2,
};
