import { z } from "zod";
import { RANGES, REQUIRED_ERR_MSG } from "./constants";
import axios, { AxiosError } from "axios";
import AmplitudeProvider from "src/AmplitudeProvider";
import { UseCountryReturn } from "./useCountry";

export const getMinMaxFromRangeOptions = (
  rangeOption: keyof typeof RANGES | string
) => {
  if (!RANGES.hasOwnProperty(rangeOption)) {
    return { min: 0, max: 0 };
  }
  return RANGES[rangeOption as keyof typeof RANGES];
};

// Range Key is used to render correct values for min and max in select component
export const getRangeKeyFromValues = ({
  min,
  max,
}: {
  min: number;
  max: number;
}): keyof typeof RANGES | "" =>
  (Object.keys(RANGES).find((key) => {
    const range = RANGES[key as keyof typeof RANGES];

    return range.min === min && range.max === max;
  }) as keyof typeof RANGES | undefined) ?? "";

export type Nullable<T> = { [K in keyof T]: T[K] | null };

export type Transform<T> = {
  keys: Partial<keyof T>[] | "ALL_FIELDS";
  condition: (value: any) => boolean;
  transform: (value: any) => any;
};

export function getDefaultFormValues<
  T extends { [K in keyof T]: T[K] },
  A extends { [K in keyof A]: A[K] }
>(
  obj: Partial<T> | undefined,
  transforms: Transform<T>[] | Transform<any>[],
  defaultObj: A
) {
  return Object.fromEntries(
    Object.entries(defaultObj).map(([k, defaultValue]) => {
      const key = k as keyof T;
      const value = obj?.hasOwnProperty(key) ? obj[key] : defaultValue;

      for (const { keys, condition, transform } of transforms) {
        if (Array.isArray(keys) && keys.includes(key) && condition(value)) {
          return [key, transform(value)];
        }
      }

      const transformAll = transforms.find((t) => t.keys === "ALL_FIELDS");

      if (transformAll && transformAll.condition(value)) {
        return [key, transformAll.transform(value)];
      }

      return [key, value];
    })
  );
}

export function transformObject<T extends { [K in keyof T]: T[K] }, ReturnType>(
  obj: T,
  transforms: Transform<T>[]
) {
  return Object.fromEntries(
    Object.entries(obj).map(([k, value]) => {
      const key = k as keyof T;

      for (const { keys, condition, transform } of transforms) {
        if (Array.isArray(keys) && keys?.includes(key) && condition(value)) {
          return [key, transform(value)];
        }
      }

      const transformAll = transforms.find((t) => t.keys === "ALL_FIELDS");

      if (transformAll && transformAll.condition(value)) {
        return [key, transformAll.transform(value)];
      }

      return [key, value];
    })
  ) as ReturnType;
}

export const zAffiliationsRefinement =
  (country: UseCountryReturn, percentageOwnershipAvailable?: number) =>
  (data: { [key: string]: any }, refinementContext: z.RefinementCtx) => {
    const {
      is_affiliated_exchange_or_finra,
      affiliated_firm,
      is_control_person,
      controlling_firms,
      country_of_tax_residence,
      tax_id_type,
      tax_id,
      country_of_citizenship,
    } = data;

    const countryOfCitizenshipAlpha3 = country.nameToAlpha3(
      country_of_citizenship
    );

    if (!countryOfCitizenshipAlpha3) {
      refinementContext.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Select country from dropdown",
        path: ["country_of_citizenship"],
      });
    }

    const countryOfTaxResidenceAlpha3 =
      country.nameToAlpha3(country_of_tax_residence) ?? "";

    if (!countryOfTaxResidenceAlpha3) {
      refinementContext.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Select country from dropdown",
        path: ["country_of_tax_residence"],
      });
    }

    const countryTaxIdTypes =
      country?.infos[countryOfTaxResidenceAlpha3]?.tax_id_types;

    const taxIdTypeMatch = countryTaxIdTypes?.find(
      ({ id_type }) => id_type === tax_id_type
    );
    const regexp = taxIdTypeMatch?.regexp;

    // Tax ID Type must be valid for the country
    if (countryTaxIdTypes && !taxIdTypeMatch) {
      refinementContext.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Invalid Tax ID Type",
        path: ["tax_id_type"],
      });
    }

    // Tax ID must match the regexp for the tax ID type, if it exists
    if (regexp && !new RegExp(regexp).test(tax_id)) {
      refinementContext.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Invalid Tax ID",
        path: ["tax_id"],
      });
    }

    if (is_affiliated_exchange_or_finra && !affiliated_firm) {
      refinementContext.addIssue({
        code: z.ZodIssueCode.custom,
        message: REQUIRED_ERR_MSG,
        path: ["affiliated_firm"],
      });
    }

    if (is_control_person && !controlling_firms) {
      refinementContext.addIssue({
        code: z.ZodIssueCode.custom,
        message: REQUIRED_ERR_MSG,
        path: ["controlling_firms"],
      });
    }
    
    if (
      typeof percentageOwnershipAvailable === "number" &&
      data?.percentage_ownership &&
      data.percentage_ownership > percentageOwnershipAvailable
    ) {
      refinementContext.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Sum of ownership cannot exceed 100%. ${percentageOwnershipAvailable}% available.`,
        path: ["percentage_ownership"],
      });
    }
  };

export const zNonEmptyString = z
  .string()
  .refine((s) => !!s?.trim(), { message: REQUIRED_ERR_MSG });

export const zPhoneNumber = z
  .string({ errorMap: () => ({ message: REQUIRED_ERR_MSG }) })
  .min(10, "Invalid phone number")
  .regex(/^\+?[ 0-9\-\(\)]+$/, "Invalid phone number");

export const zIsoDate = z
  .string({ errorMap: () => ({ message: REQUIRED_ERR_MSG }) })
  .regex(/^\d\d\d\d-\d\d-\d\d$/, "Invalid date")
  .refine((d) => {
    const [year, month, day] = d.split("-").map((n) => parseInt(n, 10));
    const isLeapYear =
      year % 4 === 0 && (!(year % 100 === 0) || year % 400 === 0);
    switch (month) {
      case 1:
      case 3:
      case 5:
      case 7:
      case 8:
      case 10:
      case 12:
        return day > 0 && day <= 31;
      case 4:
      case 6:
      case 9:
      case 11:
        return day > 0 && day <= 30;
      case 2:
        return day > 0 && day <= (isLeapYear ? 29 : 28);
      default:
        return false;
    }
  }, "Invalid date");

export const getErrorMessage = (err: Error | AxiosError): string => {
  if (axios.isAxiosError(err)) {
    const resp = err.response?.data;
    return resp?.debug || resp?.message;
  }
  return err.message;
};

export const logAmplitudeEvent = {
  onboardingStarted: () => {
    AmplitudeProvider.dispatch("algodash_entity_onboarding_started");
  },
  onboardingCompleted: (duration?: number) => {
    AmplitudeProvider.dispatch("algodash_entity_onboarding_completed", {
      duration,
    });
  },
  nextClicked: () => {
    AmplitudeProvider.dispatch("algodash_entity_onboarding_next_clicked", {
      url: window.location.pathname,
    });
  },
  backClicked: () => {
    AmplitudeProvider.dispatch("algodash_entity_onboarding_back_clicked", {
      url: window.location.pathname,
    });
  },
  invalidInput: (message: string) => {
    AmplitudeProvider.dispatch("algodash_entity_onboarding_invalid_input", {
      message,
      url: window.location.pathname,
    });
  },
  addOwnerClicked: (type: "ubo" | "authorized-individual") => {
    AmplitudeProvider.dispatch("algodash_entity_onboarding_add_owner_clicked", {
      type,
      url: window.location.pathname,
    });
  },
  editOwnerClicked: (type: "ubo" | "authorized-individual") => {
    AmplitudeProvider.dispatch(
      "algodash_entity_onboarding_edit_owner_clicked",
      {
        type,
        url: window.location.pathname,
      }
    );
  },
  pageCompleted: (duration?: number) => {
    AmplitudeProvider.dispatch("algodash_entity_onboarding_page_completed", {
      duration,
      url: window.location.pathname,
    });
  },
};

// Handle Tax ID masking - required for any Tax ID inputs that could use USA-SSN
export const onTaxIdKeyDown =
  (taxIDType: string): React.KeyboardEventHandler<HTMLInputElement> =>
  (evt) => {
    // we are only masking domestic SSN
    if (taxIDType !== "USA_SSN") {
      return;
    }
    const value = evt.currentTarget.value;
    // handle user input of hyphen
    if (value.length === 3 || value.length === 6) {
      evt.key !== "-" &&
        /[0-9]/.test(evt.key) &&
        (evt.currentTarget.value += "-");
    }
  };
