import { CountryInfo } from "src/v2/types";
import { useEffect, useState } from "react";
import { getCountryInfos } from "../rest/onboarding";

export const useGetCountryInfos = () => {
  const [infos, setInfos] = useState<{ [key: string]: CountryInfo }>({});

  useEffect(() => {
    async function fetch() {
      setInfos(await getCountryInfos());
    }
    fetch();
  }, []);

  return {
    infos,
    canLimitedFund: (alpha3: string) => alpha3 === "USA",
  };
};

export type UseGetCountyInfosReturn = ReturnType<typeof useGetCountryInfos>;

export default useGetCountryInfos;
