import useRequest, { QueryOptions } from "./useRequest";

import { UseQueryResult } from "react-query";
import {
  DocumentRequestResponse,
  getAccountDocumentRequests,
} from "../rest/account";

export const useGetAccountDocumentRequests = (
  accountId?: string,
  options?: QueryOptions<DocumentRequestResponse[]>
): UseQueryResult<DocumentRequestResponse[]> => {
  return useRequest(
    ["account_document_requests", accountId],
    () => getAccountDocumentRequests(accountId || ""),
    {
      ...options,
    }
  );
};
