import { createAction, createReducer } from "redux-act";
import { notification } from "antd";
import Auth from "@aws-amplify/auth";
import { setUserState } from "reducers/auth/common";
import { getCurrentAuthenticatedUser } from "../utils";

export const REDUCER = "profile";
const NS = `@@${REDUCER}/`;

const _setPhoneNumberToVerify = createAction(`${NS}SET_PHONE_NUMBER_TO_VERIFY`);
const _setPhoneNumberVerificationError = createAction(
  `${NS}SET_PHONE_NUMBER_VERIFICATION_ERROR`
);

export const submit = (values) => (dispatch) => {
  dispatch(updateInCognito(values));
};

// Please keep in mind that when extending the capabilities of this function that cognito has a permisive
// scope for the AccessToken that they are returning and there is no way of configuring that. This in turn
// helps hackers exploit the AccessToken to change sensitive information such as email and phone number of the cognito account.
// We want to avoid changing attributes using aws-amplify in the future and just direct the calls to gobroker.
// This would help maintain the consistency of the data and as well as prevent the exploit.
// More details of the exploit over here: https://alpaca.atlassian.net/browse/ENG-9903
const updateInCognito = (values) => (dispatch, getState) => {
  const state = getState();

  // If the user stored a phone number, then they will be sent a verification code.
  // Ensure state updates so that the form page knows to display a form to allow verification.
  if (
    values.phone_number &&
    values.phone_number !== state.auth.userState.attributes.phone_number
  ) {
    dispatch(_setPhoneNumberToVerify(true));
  }

  // If the user also sent fields to reset their password, do that too.
  if (values.old_password && values.new_password) {
    // Only the current user can change their password of course
    // Even if someone else stole a user's password, logged in, and changed it...
    // The original user could use "forgot password" and reset it from the login screen.
    // IF we also handled the API to change a username/e-mail, the attacker wouldn't be
    // able to change the e-mail either because a verification code is sent for that API method.
    getCurrentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(
          user,
          values.old_password,
          values.new_password
        );
      })
      .then(() => {
        // TODO: It would be nice to also send an e-mail notifying the user that someone, hopefully them,
        // changed their password. If it was them, ignore, if it wasn't, forgot password, etc. etc.

        notification.open({
          type: "success",
          message: "Your password was changed",
        });
        dispatch(refreshUserAttributes());
      })
      .catch((err) => {
        switch (err.code) {
          case "NotAuthorizedException": {
            notification.open({
              type: "error",
              message: "Entered old password is incorrect",
            });
            break;
          }
          default: {
            notification.open({
              type: "error",
              message: err.message,
            });
            break;
          }
        }
        console.debug(err);
      });
  } else {
    // Just a profile update, no password change
    notification.open({
      type: "success",
      message: "Your profile was updated",
    });
    dispatch(refreshUserAttributes());
  }
};

/**
 * Resends a code to verify an attribute.
 */
export const resendAttributeVerification =
  (attribute = "phone_number") =>
  (dispatch) => {
    return Auth.verifyCurrentUserAttribute(attribute).then(() => {
      if (attribute === "phone_number") {
        return dispatch(_setPhoneNumberToVerify(true));
      }
    });
  };

/**
 * Refreshes user attributes (and bypasses any cache).
 */
export const refreshUserAttributes = () => (dispatch) => {
  // Update the data fresh
  return getCurrentAuthenticatedUser().then((user) => {
    user.getUserData(
      () => {
        // Now we can get fresh
        return getCurrentAuthenticatedUser().then((freshUser) => {
          return dispatch(
            setUserState({
              userState: freshUser,
            })
          );
        });
        //
      },
      { bypassCache: true }
    );
  });
};

const initialState = {
  phoneNumberToVerify: false,
};

export default createReducer(
  {
    [_setPhoneNumberToVerify]: (state, phoneNumberToVerify) => ({
      ...state,
      phoneNumberToVerify,
    }),
    [_setPhoneNumberVerificationError]: (
      state,
      phoneNumberVerificationError
    ) => ({ ...state, phoneNumberVerificationError }),
  },
  initialState
);
