import { useMemo } from "react";
import { useRequest } from ".";
import { getOrders, Order } from "../rest/account";
import { QueryOptions } from "./useRequest";

export const useGetOrders = (
  accountID: string,
  product: string,
  options?: QueryOptions<Order[]>,
  status?: "open" | "closed" | "all",
  side?: "buy" | "sell" | "sell_short",
  limit?: number
) => {
  const { data, refetch, isLoading } = useRequest(
    ["orders", accountID, status, side, limit],
    () =>
      getOrders({
        accountID: accountID || "",
        paper: product === "paper",
        symbols: [],
        status: status ? status : "all",
        side: side,
        limit,
      }),
    options
  );

  // Required to avoid infinite rerender loop
  const emptyOrders = useMemo(() => [], []);

  return { orders: data || emptyOrders, refetch, isLoading };
};

export default useGetOrders;
