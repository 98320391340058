import React, { useCallback, useEffect, useState } from "react";
import { Spinner } from "@alpacahq/ui";
import loadable, { LoadableClassComponent } from "@loadable/component";
import { enquireScreen } from "enquire-js";

import { Page } from "src/v2/components/common/page";
import App from "src/v2/pages";
import { MFAGuard } from "src/MFAGuard";

let isMobile: boolean;
enquireScreen((b) => {
  isMobile = b;
});

interface LayoutPropsDefaultValues {
  disableAppMenu: boolean;
  headerContent: any;
  noHeader: boolean;
  showBrandInHeader: boolean;
  showSearchInHeader: boolean;
  product: string;
  importer?: (props?: any) => Promise<any>;
  children?: React.ReactNode;
}

export type LayoutProps = Partial<LayoutPropsDefaultValues>;

const DEFAULT_PROPS: LayoutPropsDefaultValues = {
  disableAppMenu: false,
  headerContent: null,
  noHeader: false,
  showBrandInHeader: false,
  showSearchInHeader: true,
  product: "alpaca",
  importer: undefined,
  children: undefined,
};

export const Layout = (partialProps?: LayoutProps) => {
  const props: LayoutPropsDefaultValues = { ...DEFAULT_PROPS, ...partialProps };
  const {
    disableAppMenu,
    noHeader,
    importer,
    product: initialProduct,
    children,
  } = {
    ...props,
  };

  const [product, setProduct] = useState(initialProduct);
  const [LoadableChild, setLoadableChild] =
    useState<LoadableClassComponent<any> | null>(null);

  const loadComponent = useCallback(() => {
    if (!importer) {
      return;
    }

    const LoadableComponent = loadable(() => importer({ product }), {
      fallback: (
        <div className="w-full h-screen flex justify-center items-center">
          <Spinner />
        </div>
      ),
    });

    setLoadableChild(LoadableComponent);
  }, [importer, product]);

  useEffect(() => {
    enquireScreen();

    loadComponent();
  }, [importer, loadComponent]);

  useEffect(() => {
    // only save non-dynamic product state to local storage
    if (initialProduct !== "dynamic") {
      window.localStorage.setItem("product", initialProduct);
    } else {
      // set product from local storage to mimic a persistent state
      const savedProduct = window.localStorage.getItem("product") || "paper";
      setProduct(savedProduct === "dynamic" ? "paper" : savedProduct);
    }
  }, [initialProduct]);

  return (
    <App>
      <Page
        product={product}
        noHeader={noHeader}
        disableAppMenu={disableAppMenu}
      >
        <MFAGuard>
          {LoadableChild && <LoadableChild {...props} product={product} />}
          {children && children}
        </MFAGuard>
      </Page>
    </App>
  );
};

export default Layout;
